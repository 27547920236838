// https://maketintsandshades.com/#0D98BA COLOR SHADES

// 1. Import the initial variables
@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";
// 2. Setup your Custom Colors
$primary: #0D98BA;
// const PRIMARY_BLACK_COLOR = '#292929';
$secondary: #fbfbfb;
$thirdary: #cfeaf1;
$quadary: #EBF5F8;
$primaryWhite: #fbfbfb;
$primaryGradientDark: #074c5d;
$primaryLight: #6ec1d6;
$primaryGreenCorrect: #bdeedf;
$primary-invert: findColorInvert($primary);
// 3. Add new color variables to the color map.
@import "bulma/sass/utilities/derived-variables.sass";
$addColors: (
  "primaryColor":($primary, $primary-invert),
);
$colors: map-merge($colors, $addColors);
// colorize to primary
$tabs-boxed-link-active-background-color: $primary;
$tabs-boxed-link-hover-background-color: $primary;
// $navbar-background-color: $primary;
$navbar-background-color: $primary;
// $navbar-item-hover-background-color: $primary;
$navbar-item-hover-background-color: $primary;
@import 'bulma/bulma';
// import table style
@import './styles/bulma-responsive-tables.scss';

// NAV BAR CSS TODO: remove the below this is reference only //
// .navbar {
//   height: 50px !important;
// }

// .nav-hamburger {
//   height: 50px !important;
//   padding: 0 !important;
//   margin-right: 2% !important;  // Pushes the hamburger 10% from the right edge
// }

// .navbar-brand {
//   display: flex !important;
//   margin-right: 0 !important;
//   align-items: center !important;  // Vertically centers child elements
//   justify-content: flex-end !important;  // Horizontally aligns children to the right
// }

// .fix-wrapper {
//   overflow: hidden;  /* Ensures no internal content overflows */
//   position: relative;  /* Sets a reference point for any absolute positioned children */
// }

// .nav-hamburger span {
//   height: 4px; // Adjust the thickness if needed
//   margin-top: 3px; 
//   margin-bottom: 3px;
// }

// .navbar-item img {
//   max-height: 50px; // Adjust based on your design preference
// }

// *dragons beware*: this is associated to view port flex for header and footer
// we can make this better in the future
.home-wrapper {
  display: flex;
  min-height: calc(100vh - 243px);
  flex-direction: column;
  justify-content: space-between;
  // TODO: added to fix mobile issues 10-23-2023
  overflow: hidden;

  // Mobile-specific styles
  @media (max-width: 767px) {
    padding: 5px;
    margin-top: 20px;
  }
}

.centered {
  /* Magic here */
  height: 73vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-full-width {
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

.is-primary-bg {
  background: $primary !important;
}

.is-secondary-bg {
  background-color: $secondary;
}

.is-thirdary-bg {
  background-color: $thirdary !important;
}

.is-quadary-bg {
  background-color: $quadary !important;
}

.is-primary-light-bg {
  background-color: $primaryLight !important;
}

.is-primary-dark-bg {
  background-color: $primaryGradientDark;
}

.is-primary-green-correct-bg {
  background-color: $primaryGreenCorrect !important;
}

.is-primary-txt {
  color: $primary !important;
}

.is-secondary-txt {
  color: $secondary
}

.is-primary-dark-txt {
  color: $primaryGradientDark !important
}

.is-primary-light-txt {
  color: $primaryLight !important
}

.is-primary-white-background {
  background-color: $primaryWhite !important;
}

.is-white-txt {
  color: $primaryWhite !important;
}

.is-primary-white-button-fill {
  background-color: $primaryWhite;
  border-radius: 10px;
  border-color: $primaryWhite;
}

.is-secondary-border {
  border-radius: 10px;
  border-color: $secondary;

}

.is-secondary-border:hover {
  border-radius: 10px;
  border-color: $primary;
}

.is-secondary-border:focus {
  border-radius: 10px;
  border-color: $primary;
}

.is-secondary-border::placeholder {
  color: black;
  opacity: .7;
}

.is-primary-button-fill {
  background-color: $primary;
  border-radius: 10px;
  border-color: $primary;
}

.is-primary-dark-button-fill {
  background-color: $primaryWhite !important;
  border-radius: 10px;
  border-width: 2px;
  font-weight: 500;
  border-color: $primaryGradientDark !important;
  color: $primaryGradientDark !important;
}

.is-primary-outline-button-fill {
  background-color: $primaryWhite !important;
  border-radius: 10px;
  border-width: 2px;
  font-weight: 500;
  border-color: $primary !important;
  color: $primaryGradientDark !important;
}

.nav-hamburger {
  background: $primary;
  border-color: $primary;
  height: 4.25rem;
}

.is-margin-left-li {
  margin-left: 20px;
}

.is-margin-left-ol {
  margin-left: 20px;
}

.is-tab-active {
  background-color: $secondary !important;
  border-radius: 10px !important;
  border-color: $secondary !important;
  color: $primary !important;
}

.is-active-main-tab {
  background-image: linear-gradient($primaryWhite, $primaryWhite);
  background-size: 95% 4px;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.is-padding-rl-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.is-image-choosen-right-button {
  padding-right: 100px
}

.is-image-choosen-left-button {
  padding-left: 100px
}

@media (max-width: 900px) {
  .is-image-choosen-right-button {
    padding-right: 30px;
    padding-left: 30px;
  }

  .is-image-choosen-left-button {
    padding-left: 30px;
    padding-right: 30px;
  }

  .is-display-none-mobile {
    display: none !important
  }
}

.is-code-capture-selection {
  color: $primary;
  font-weight: 500;
  background-color: $primaryWhite;
  border-color: $primary !important;
}

.is-batch-upload {
  width: 100% !important;
  min-width: 100% !important;
  height: 100px !important;
}

/* LoadMoreButton.css move out */
.load-more-button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.has-text-underline {
  text-decoration: underline;
}

.highlighted-word {
  background-color: greenyellow;
}

.highlighted-body-part {
  background-color: lightsalmon;
}

.highlighted-chemical {
  background-color: lightskyblue;
}

// TODO: breakout, but this if or the messaging compoennt pieces
.message-container {
  display: flex;
  width: 100%;
  /* Align items vertically */
  align-items: center;
}

.message-input {
  flex-grow: 1;
  max-height: 35vh;
  resize: none;
  /* Adjust this to create space for buttons */
  padding-right: 100px;
  border: none;
  /* Remove border */
  outline: none;
  /* Remove outline */
}

.message-buttons {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  gap: 15px;
}

.attach-button,
.send-button {
  text-decoration: none;
  color: inherit;
}

/* Add your styling for the icons here */
.fas.fa-paperclip,
.fas.fa-paper-plane {
  /* Adjust this to make the icons larger or smaller */
  font-size: 20px;
  width: 40px;
  /* was 30px */
  height: 40px;
  /* was 30px */
  display: inline-block;
  background-color: #ccc;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}

.attach-button.active {
  // highlight color
  // background-color: $primary;
  color: $primary
}

.message-box-container {
  background-color: $thirdary;
  padding: 7px;
}

.rightAlignedText {
  text-align: right;
}

.rightAlignedTextBoldUnderline {
  text-align: right;
  font-weight: bold;
  text-decoration: underline;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  /* Aligns children: one to the far left, the other to the far right */
  align-items: center;
  /* Vertically aligns children in the middle */
  margin-bottom: 20px;
  /* Optional: Add some space below the header */
}

.backButton {
  /* Your styling for the back button */
  margin-right: 20px;
  /* Optional: Add some space to the right of the button */
}

.textContainer {
  text-align: right;
  /* Aligns text to the right */
}

.rightAlignedTextBoldUnderline {
  /* Your styling for the header text */
  text-decoration: underline;
  font-weight: bold;
}

.centered-cell {
  text-align: center !important;
  vertical-align: middle !important;
}

// TODO: we might remove some of these
.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.flexItem {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.leftAlignedText {
  text-align: left;
}

.rightAlignedText {
  text-align: right;
}

.tight-columns {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.tight-columns .column {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.invalid-input {
  border-color: red;
}

input[type="checkbox"] {
  accent-color: $primary;

  &:checked {
    background-color: $primary;
    border-color: $primary;
  }

  &:focus {
    outline-color: $primary;
  }

  &:hover {
    border-color: darken($primary, 10%);
  }
}

// TODO: remove potentially, or match privacy special terms scss
.terms-container {
  min-height: 700px;
  margin-top: 20px;
}

.terms-content {
  background-color: white;
  padding: 45px;
  margin: 0 10px;
}

.terms-subtitle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.terms-paragraph {
  margin-bottom: 20px;
}

.is-primary-light-bg-only {
  background: $primaryLight !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 16rem;
}

.tooltip {
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none; /* so that hovering the tooltip doesn't affect mouse events */
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
