@mixin table-cards {
    thead {
        display: none;
    }

    tfoot {
        th {
            border: 0; // Disable is-bordered
            display: inherit;
        }
    }

    tr {
        // Card style — Cannot extend inside media query
        box-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1);
        max-width: 100%;
        position: relative;
        display: block;

        td {
            border: 0; // Disable is-bordered
            display: inherit;

            &:last-child {
                border-bottom: 0;
            }
        }

        &:not(:last-child) {
            margin-bottom: $size-base;
        }

        // Disables is-*
        &:not([class*="is-"]) {
            background: inherit;

            &:hover {
                background-color: inherit;
            }
        }

        &.detail {
            margin-top: $size-base * -1;
        }
    }

    tr:not(.detail):not(.is-empty):not(.table-footer) {
        td {
            display: flex;
            width: auto;
            justify-content: space-between;
            text-align: right;
            border-bottom: 1px solid $background;

            &:before {
                content: attr(data-label);
                font-weight: $weight-semibold;
                padding-right: $size-base * .5;
                text-align: left;
            }

            &.is-image-cell {
                .image {
                    width: $table-image-cell-collapsed-image-width;
                    height: $table-image-cell-collapsed-image-height;
                    margin: 0 auto $size-base * .5;
                }
            }

            &.is-progress-cell {

                span,
                progress {
                    display: flex;
                    width: 45%;
                    align-items: center;
                    align-self: center;
                }
            }

            &.is-checkbox-cell,
            &.is-image-cell {
                border-bottom: 0 !important;
            }

            &.is-checkbox-cell,
            &.is-actions-cell {
                &:before {
                    display: none;
                }
            }

            &.is-label-hidden,
            &.is-image-cell {
                &:before {
                    display: none;
                }
            }

            &.is-label-hidden {
                span {
                    display: block;
                    width: 100%;
                }

                &.is-progress-col {
                    progress {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.table-wrapper {
    .table {
        margin-bottom: 0;
    }

    &:not(:last-child) {
        margin-bottom: $size-base * 1.5;
    }

    @include touch {
        overflow-x: auto;
    }
}

.b-table {
    transition: opacity $speed $easing;

    .table-mobile-sort {
        @include tablet {
            display: none;
        }
    }

    .icon {
        transition: transform $speed-slow $easing, opacity $speed $easing;

        &.is-desc {
            transform: rotate(180deg);
        }

        &.is-expanded {
            transform: rotate(90deg);
        }
    }

    .table {
        width: 100%;
        border: 1px solid transparent;
        border-radius: $radius;
        border-collapse: separate;

        th {
            font-weight: $weight-semibold;

            .th-wrap {
                display: flex;
                align-items: center;

                .icon {
                    margin-left: $size-base * .5;
                    margin-right: 0;
                    font-size: $size-base;
                }

                &.is-numeric {
                    flex-direction: row-reverse;
                    text-align: right;

                    .icon {
                        margin-left: 0;
                        margin-right: $size-base * .5;
                    }
                }

                &.is-centered {
                    justify-content: center;
                    text-align: center;
                }
            }

            &.is-current-sort {
                border-color: $grey;
                font-weight: $weight-bold;
            }

            &.is-sortable:hover {
                border-color: $grey;
            }

            &.is-sortable,
            &.is-sortable .th-wrap {
                cursor: pointer;
            }

            .multi-sort-cancel-icon {
                margin-left: 10px;
            }

            &.is-sticky {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                z-index: 3 !important;
                background: $table-background-color;
            }
        }

        tr {
            &.is-selected {
                .checkbox input {
                    &:checked+.check {
                        background: $table-row-active-color url(checkmark($table-row-active-background-color)) no-repeat center center;
                    }

                    +.check {
                        border-color: $table-row-active-color;
                    }
                }
            }

            &.is-empty:hover {
                background-color: transparent;
            }
        }

        .is-chevron-cell,
        .is-checkbox-cell {
            width: $table-row-toggle-cell-width;
        }

        .is-chevron-cell {
            vertical-align: middle
        }

        .is-checkbox-cell {
            .checkbox {
                vertical-align: middle;

                &.b-checkbox {
                    margin-right: 0;
                }

                .check {
                    transition: none;
                }
            }
        }

        tr.detail {
            box-shadow: inset 0 1px 3px $grey-lighter;
            background: $white-bis;

            .detail-container {
                padding: 1rem;
            }
        }

        &:focus {
            border-color: $link;
            box-shadow: 0 0 0 0.125em rgba($link, 0.25);
        }

        // Modifiers
        &.is-bordered {

            th.is-current-sort,
            th.is-sortable:hover {
                border-color: $grey-lighter;
                background: $background;
            }
        }

        td {
            &.is-sticky {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                z-index: 1;
                background: $table-background-color;
            }

            &.is-image-cell {
                .image {
                    margin: 0 auto;
                    width: $table-image-cell-image-width;
                    height: $table-image-cell-image-height;
                }
            }

            &.is-progress-cell {
                min-width: 5rem;
                vertical-align: middle;
            }
        }
    }

    /*.level:not(.top) {
      padding-bottom: $size-base * 1.5;
    }*/

    .table-wrapper {
        &.has-sticky-header {
            height: $table-sticky-header-height;
            overflow-y: auto;

            &.has-mobile-cards {
                @include mobile {
                    height: initial !important;
                    overflow-y: initial !important;
                }
            }

            tr:first-child {
                th {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    z-index: 2;
                    background: $table-background-color;
                }
            }
        }

        &.has-mobile-cards {
            @include mobile {
                @include table-cards
            }
        }

        &.is-card-list {
            @include table-cards
        }
    }

    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;

        &:after {
            @include loader;
            position: absolute;
            top: 4em;
            left: calc(50% - 2.5em);
            width: 5em;
            height: 5em;
            border-width: 0.25em;
        }
    }

    &.has-pagination {
        .table-wrapper {
            margin-bottom: 0;

            &+.notification {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}